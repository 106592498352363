import React, { useState } from 'react';
import {
  CloseButton, Form, Button, Spinner, Alert,
} from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useHistory } from 'react-router-dom';

import { useStore } from '../../store/Store';
import { addCoffee } from '../../store/FeedReducer';
import Rating from '../Rating';
import TakePhoto from '../TakePhoto';

import { useAuth } from '../../context/Auth';
import { fetchCoffeeSuggestions, submitNewCoffee } from '../../services/api.ts';
import brewingMethods from '../../services/brewing-methods.ts';

import './style.css';

function useFetchSuggestions() {
  const [isLoadingSuggestions, setLoadingSuggestions] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const { authToken } = useAuth();

  const fetchSuggestions = (query) => {
    setLoadingSuggestions(true);
    fetchCoffeeSuggestions(authToken, query)
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res;
      })
      .then((res) => res.json())
      .then((body) => {
        setLoadingSuggestions(false);
        setSuggestions(body);
      })
      .catch(() => {
        console.log('error!'); // TODO
      });
  };

  return {
    fetchSuggestions,
    isLoadingSuggestions,
    suggestions,
  };
}

function useSubmitData() {
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const [isSubmitSuccess, setSubmitSuccess] = useState(false);
  const [isSubmitError, setSubmitError] = useState(false);
  const { authToken } = useAuth();
  const [, dispatch] = useStore();

  const submitData = (data) => {
    setSubmitLoading(true);
    setSubmitSuccess(false);
    setSubmitError(false);
    submitNewCoffee(authToken, data)
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res;
      })
      .then((res) => res.json())
      .then((body) => {
        console.log('new coffee response', body);
        setSubmitLoading(false);
        setSubmitSuccess(true);
        dispatch(addCoffee(body));
      })
      .catch(() => {
        setSubmitLoading(false);
        setSubmitError(true);
        console.log('error!'); // TODO
      });
  };

  return {
    isSubmitLoading,
    isSubmitSuccess,
    isSubmitError,
    submitData,
  };
}

export default function NewCoffee() {
  const { fetchSuggestions, isLoadingSuggestions, suggestions } = useFetchSuggestions();
  const {
    isSubmitLoading, isSubmitError, isSubmitSuccess, submitData,
  } = useSubmitData();

  const [validated, setValidated] = useState(false);
  const [coffeeName, setCoffeeName] = useState();
  const [coffeeInfo, setCoffeeInfo] = useState();
  const [coffeeBrewMethod, setCoffeeBrewMethod] = useState();
  const [coffeeComment, setCoffeeComment] = useState();
  const [coffeeRating, setCoffeeRating] = useState(undefined);
  const [coffeeImageData, setCoffeeImageData] = useState(undefined);

  const history = useHistory();

  const closeForm = () => {
    history.goBack();
  };

  const updateRating = ({ rating }) => {
    console.log(`update rating: ${rating}`);
    setCoffeeRating(rating);
  };

  function sendForm() {
    console.log(`coffeeName=${coffeeName} coffeeInfo=${JSON.stringify(coffeeInfo)}`);
    const data = {
      coffeeId: coffeeInfo !== undefined ? coffeeInfo.id : undefined,
      coffeeName: coffeeInfo === undefined ? coffeeName : undefined,
      brewMethod: coffeeBrewMethod,
      rating: coffeeRating,
      comment: coffeeComment,
      imageData: coffeeImageData,
    };
    console.log(`sending data: ${JSON.stringify(data)}`);
    submitData(data);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      console.log('submit');
      sendForm();
    }
    setValidated(true);
  };

  const handleSearch = (query) => {
    setCoffeeName(query);
    fetchSuggestions(query);
  };

  const handleSelectSuggestion = (suggestion) => {
    console.log(`selected suggestion: ${JSON.stringify(suggestion)}`);
    setCoffeeInfo(suggestion);
    setCoffeeName(undefined);
  };

  if (isSubmitSuccess) {
    return (
      <div>
        <Alert variant="success">Koffie aangemaakt!</Alert>
        <Button variant="primary" type="submit" onClick={closeForm}>
          Sluiten
        </Button>
      </div>
    );
  }

  let buttonContent;
  if (isSubmitLoading) {
    buttonContent = (
      <div className="buttonSpinner">
        <Spinner animation="border" size="sm" />
        <span>Bezig met versturen...</span>
      </div>
    );
  } else {
    buttonContent = <span>Voeg Toe</span>;
  }

  return (
    <div className="newCoffee">
      <CloseButton onClick={closeForm} disabled={isSubmitLoading} />
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Welke koffie?</Form.Label>
          <AsyncTypeahead
            id="coffeeNameInput"
            isLoading={isLoadingSuggestions}
            labelKey="name"
            placeholder="Koffie of merk naam"
            minLength={3}
            onSearch={handleSearch}
            options={suggestions}
            onChange={(opt) => handleSelectSuggestion(opt[0])}
            disabled={isSubmitLoading}
          />
          <Form.Control.Feedback type="invalid">
            Vul de koffiesoort zo volledig mogelijk in
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="brewMethod">
          <Form.Label>Wat is de zetmethode?</Form.Label>
          <Form.Control
            as="select"
            disabled={isSubmitLoading}
            onChange={(e) => setCoffeeBrewMethod(e.target.value)}
            value={coffeeBrewMethod}
            required
          >
            <option key="0" value="">Kies zetmethode</option>
            {
              Object.entries(brewingMethods).map(
                ([code, title]) => <option key={code} value={code}>{title}</option>,
              )
            }
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            Zijn het bonen of kies de juiste maling.
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Wat vind je er van?</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            disabled={isSubmitLoading}
            onChange={(e) => setCoffeeComment(e.target.value)}
            value={coffeeComment}
          />
        </Form.Group>
        <Form.Group>
          <Rating
            rating={coffeeRating}
            readonly={isSubmitLoading}
            onChangeRating={updateRating}
          />
        </Form.Group>
        <Form.Group>
          <TakePhoto onPhotoTaken={(data) => setCoffeeImageData(data)} />
        </Form.Group>
        { isSubmitError && <Alert variant="danger">Toevoegen mislukt. Probeer het later nog een keer.</Alert>}
        <Button variant="primary" type="submit" disabled={isSubmitLoading}>
          {buttonContent}
        </Button>
      </Form>
    </div>
  );
}
