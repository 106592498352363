import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Modal,
  Button,
  Container,
  Row,
  Col,
  Nav,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import Rating from '../../components/Rating';
import ImageGallery from '../../components/image-gallery';
import { useAuth } from '../../context/Auth';
import { fetchFeedDetail } from '../../services/api.ts';
import brewingMethods from '../../services/brewing-methods.ts';

import './style.css';

function useFetchData() {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { authToken } = useAuth();
  const { feedId } = useParams();
  useEffect(() => {
    setLoading(true);
    fetchFeedDetail(authToken, feedId)
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText);
        }
        return res;
      })
      .then((res) => res.json())
      .then((body) => {
        setLoading(false);
        setData(body);
      })
      .catch(() => {
        console.log('error!'); // TODO
      });
  }, [authToken, feedId]);
  return {
    isLoading,
    data,
  };
}

function FeedDetail() {
  const { isLoading, data } = useFetchData();
  const history = useHistory();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const {
    coffeeName, coffeeRating, averageRating, comment, imageUrl, productImageUrl, brandImageUrl, brandName, brandId,
    coffeeBrewMethod,
  } = data;

  const images = [];
  if (imageUrl) {
    images.push(imageUrl);
  }
  if (productImageUrl) {
    images.push(productImageUrl);
  }
  if (brandImageUrl) {
    images.push(brandImageUrl);
  }

  const navigateToBrand = () => {
    history.push(`/brand/${brandId}`);
  };

  return (
    <Modal show fullscreen>
      <Modal.Header>
        <Modal.Title>
          <Button variant="outline-primary" aria-label="Back" onClick={() => history.goBack()}>
            <FontAwesomeIcon icon={faArrowLeft} />
            &nbsp;
            <span>{coffeeName}</span>
          </Button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="coffeeDetail">
        <Container>
          <Row>
            <Col>
              <div>
                <div className="label">Koffie</div>
                <div className="value">{coffeeName}</div>
              </div>
            </Col>
            <Col>
              <div>
                <div className="label">Merk</div>
                { brandName
                  && <div className="value"><Nav.Link onClick={navigateToBrand}>{brandName}</Nav.Link></div>}
                { !brandName
                  && <div className="value">(onbekend)</div>}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                <div className="label">Zetmethode</div>
                <div className="value">{brewingMethods[coffeeBrewMethod] || '(onbekend)'}</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col />
          </Row>
          <Row>
            <Col>
              <div>
                <div className="label">Jouw Score</div>
                <div className="value">
                  <Rating
                    rating={coffeeRating}
                    readOnly
                  />
                  <span className="rating small">{coffeeRating}</span>
                </div>
              </div>
            </Col>
            <Col>
              <div>
                <div className="label">Gemiddelde Score</div>
                <div className="value">
                  <Rating
                    rating={averageRating}
                    readOnly
                  />
                  <span className="rating small">{averageRating}</span>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                <div className="label">Ervaring</div>
                <div className="value">
                  { comment && (
                  <div className="comment">
                    {comment}
                  </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="imageContainer">
          { images && (
          <ImageGallery images={images} />
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default FeedDetail;
