import React from 'react';
import { Route, BrowserRouter as Router, Redirect } from 'react-router-dom';
import FeedOverview from './pages/feed-overview';
import Login from './components/Login';
import Logout from './components/Logout';
import PrivateRoute from './PrivateRoute';
import { AuthProvider } from './context/Auth';
import { StoreProvider } from './store/Store';
import FeedDetail from './pages/feed-detail';
import BrandDetail from './pages/brand-detail';
import Signup from './pages/signup';
import NewCoffee from './components/new-coffee';
import Search from './pages/search';
import AdminHome from './pages/admin-home';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <div className="App">
      <header>
        <AuthProvider>
          <StoreProvider>
            <Router basename="/#app">
              <PrivateRoute exact path="/" component={FeedOverview} />
              <Route path="/login" component={Login} />
              <Route path="/logout" component={Logout} />
              <Route path="/detail/:feedId" component={FeedDetail} />
              <Route path="/brand/:brandId" component={BrandDetail} />
              <Route path="/signup" component={Signup} />
              <Route path="/new-coffee" component={NewCoffee} />
              <Route path="/search" component={Search} />
              <Route path="/admin">
                <Route path="/" component={AdminHome} />
              </Route>
              <Redirect to="/" />
            </Router>
          </StoreProvider>
        </AuthProvider>
      </header>
    </div>
  );
}

export default App;
